import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var cards = new Swiper(".cards-carousel", {
	slidesPerView: "1.5",
	spaceBetween: 32,
	slidesOffsetBefore: 32,
	slidesOffsetAfter: 32,
	navigation: {
		prevEl: ".swiper-prev",
		nextEl: ".swiper-next",
	},
	breakpoints: {
		768: {
			slidesPerView: "2.25",
			spaceBetween: 40,
			slidesOffsetBefore: 40,
			slidesOffsetAfter: 40,
		},
		1024: {
			slidesPerView: "2.95",
			spaceBetween: 56,
			slidesOffsetBefore: 56,
			slidesOffsetAfter: 56,
		},
		1280: {
			slidesPerView: "3.75",
			spaceBetween: 80,
			slidesOffsetBefore: 80,
			slidesOffsetAfter: 80,
		},
		1600: {
			slidesPerView: "3.75",
			spaceBetween: 80,
			slidesOffsetBefore: 96,
			slidesOffsetAfter: 96,
		}
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
});

var boxyCards = new Swiper(".boxy-cards-carousel", {
	slidesPerView: "1.35",
	spaceBetween: 32,
	slidesOffsetBefore: 32,
	slidesOffsetAfter: 32,
	navigation: {
		prevEl: ".swiper-prev",
		nextEl: ".swiper-next",
	},
	breakpoints: {
		768: {
			slidesPerView: "1.75",
		},
		1024: {
			slidesPerView: "1.875",
		},
		1280: {
			slidesPerView: "1.5",
		}
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
});

var vertical = new Swiper(".vertical", {
	direction: "vertical",
	autoplay: {
		delay: 2500,
		disableOnInteraction: false,
	},
});